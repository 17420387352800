import React, { FC } from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { BlogJsonLd } from 'gatsby-plugin-next-seo';
const Gather: FC = ({ data }:any) => {
 
  return (
    <>
      <BlogJsonLd
        url="https://casafraine.com/blog/categoria"
        headline="Articoli suddivisi per categoria "
        images="https://example.com/photos/1x1/photo.jpg"
        posts={[
          {
            headline: `${data.allDatoCmsBlog.edges[0]?.node.title}`,
            image: `${data.allDatoCmsBlog.edges[0]?.node.hero.fluid}`,
          },
          {
            headline: `${data.allDatoCmsBlog.edges[1]?.node.title}`,
            image: `${data.allDatoCmsBlog.edges[1]?.node.hero.fluid}`,
          },
        ]}
        datePublished="2021-02-05T08:00:00+08:00"
        dateModified="2021-02-05T09:00:00+08:00"
        authorName="Vincenzo"
        description="Articoli suddivisi per categoria"
      />
      <div className="page-blog">
        <section style={{ width: '95%' }}>
          <h2>
            Sei nella categoria:&nbsp;
            {data.allDatoCmsBlog.edges[0]?.node.category}
          </h2>
          {data.allDatoCmsBlog.edges.map((item, index) => {
            return (
              <>
                <article key={index}>
                  <Img
                    className="small__image"
                    fluid={item.node.hero.fluid}
                    alt={item.node.title}
                  />
                  <div className="description">
                    <div className="description__top">
                      <Link replace to={`/blog/${item.node.slug}`}>
                        <h3>{item.node.title}</h3>
                      </Link>

                      <Link
                        to={`/blog/categoria/${item.node.category}`}
                        className="category category2"
                      >
                        {item.node.category}
                      </Link>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<p>${
                          item.node.excerpt.substring(0, 250).trim() + '...'
                        }<p>`,
                      }}
                    ></div>
                  </div>
                </article>
                <br />
                <br />
                <br />
              </>
            );
          })}
        </section>
      </div>
    </>
  );
};
export default Gather;

export const query = graphql`
  query CategoryeQuery($category: String!) {
    allDatoCmsBlog(filter: { category: { eq: $category } }) {
      edges {
        node {
          title
          authorImage
          description
          slug
          category
          excerpt
          meta {
            createdAt
          }
          hero {
            fluid(maxWidth: 450, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`;
